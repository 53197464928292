<template>
    <div class="page-box">
        <div class="index-column flex-center between-box">
            <router-link to="/home/approval" class="column-item flex-center">
                <div class="flex-center">
                    <img src="../../assets/img/shenpi1.png" alt=""/>
                    <el-badge
                            :value="counts.shenpi"
                            :hidden="counts.shenpi === 0"
                            class="item flex-center"
                    >
                        我审批的
                    </el-badge>
                </div>
            </router-link>
            <router-link to="/home/send" class="column-item flex-center">
                <div class="flex-center">
                    <img src="../../assets/img/faqi.png" alt=""/>
                    <span class="item flex-center"> 我发起的 </span>
                </div>
            </router-link>
            <router-link to="/home/cc" class="column-item flex-center">
                <div class="flex-center">
                    <img src="../../assets/img/chaosong.png" alt=""/>
                    <!--<el-badge-->
                    <!--:value="counts.copy"-->
                    <!--:hidden="counts.copy === 0"-->
                    <!--class="item flex-center"-->
                    <!--&gt;-->
                    <span class="item flex-center"> 抄送我的 </span>
                    <!--</el-badge>-->
                </div>
            </router-link>
        </div>
        <div class="index-executive">
            <p class="index-title">行政审批</p>
            <div class="index-executive-list">
                <router-link to="/home/subApproval" class="index-executive-item">
                    <img src="../../assets/img/shenpi.png" alt=""/>
                    <p>提交审批</p>
                </router-link>
            </div>
        </div>
        <div class="index-meet">
            <p class="index-title">会议审批</p>
            <div class="index-meet-list flex-start between-box">
                <router-link to="/home/subMerchants" class="index-meet-item">
                    <img src="../../assets/img/l1.png" alt=""/>
                    <p>招商资料</p>
                </router-link>
                <img src="../../assets/img/jiantou.png" alt="" class="meet-jiantou"/>
                <router-link to="/home/subSponsor" class="index-meet-item">
                    <img src="../../assets/img/l2.png" alt=""/>
                    <p>上游协议</p>
                </router-link>
                <img src="../../assets/img/jiantou.png" alt="" class="meet-jiantou"/>
                <router-link
                        to="/home/payment"
                        class="index-meet-item"
                        v-if="isPayment">
                    <img src="../../assets/img/l3.png" alt=""/>
                    <p>到款</p>
                </router-link>
                <img
                        src="../../assets/img/jiantou.png"
                        alt=""
                        class="meet-jiantou"
                        v-if="isPayment"/>
                <router-link to="/home/invoice" class="index-meet-item">
                    <img src="../../assets/img/l4.png" alt=""/>
                    <p>发票申请</p>
                </router-link>
                <img src="../../assets/img/jiantou.png" alt="" class="meet-jiantou"/>
                <router-link to="/home/parity" class="index-meet-item">
                    <img src="../../assets/img/l7.png" alt=""/>
                    <p>供应商比价</p>
                </router-link>
                <img src="../../assets/img/jiantou.png" alt="" class="meet-jiantou"/>
                <el-dropdown class="index-meet-item" trigger="click">
                    <img src="../../assets/img/l5.png" alt=""/>
                    <p class="el-dropdown-link">下游协议</p>
                    <el-dropdown-menu slot="dropdown">
                        <router-link to="/home/contract">
                            <el-dropdown-item>下游协议</el-dropdown-item>
                        </router-link>
                        <router-link to="/home/scontract">
                            <el-dropdown-item>特殊下游协议</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
                <img src="../../assets/img/jiantou.png" alt="" class="meet-jiantou"/>

                <el-dropdown class="index-meet-item" trigger="click">
                    <img src="../../assets/img/l6.png" alt=""/>
                    <p class="el-dropdown-link">付款</p>
                    <el-dropdown-menu slot="dropdown">
                        <router-link to="/home/pay">
                            <el-dropdown-item>项目付款</el-dropdown-item>
                        </router-link>
                        <router-link to="/home/payLw">
                            <el-dropdown-item>劳务付款</el-dropdown-item>
                        </router-link>
                        <router-link to="/home/otherPay">
                            <el-dropdown-item>支票申请</el-dropdown-item>
                        </router-link>
                        <router-link to="/home/refund">
                            <el-dropdown-item>退款</el-dropdown-item>
                        </router-link>
                        <router-link to="/home/advance">
                            <el-dropdown-item>垫付申请</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
                <img src="../../assets/img/jiantou.png" alt="" class="meet-jiantou"/>

                <el-dropdown class="index-meet-item" trigger="click">
                    <img src="../../assets/img/l7.png" alt=""/>
                    <p class="el-dropdown-link">结项</p>
                    <el-dropdown-menu slot="dropdown">
                        <router-link to="/home/fpr">
                            <el-dropdown-item>结项</el-dropdown-item>
                        </router-link>
                        <router-link to="/home/otherFpr">
                            <el-dropdown-item>专项结项</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>

                <el-dropdown class="index-meet-item" trigger="click">
                    <img src="../../assets/img/l1.png" alt=""/>
                    <p class="el-dropdown-link">其他审批</p>
                    <el-dropdown-menu slot="dropdown">
                        <router-link to="/home/otherApproval">
                            <el-dropdown-item>特殊审批</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>

                <!--                <router-link to="/home/fpr" class="index-meet-item">-->
                <!--                    <img src="../../assets/img/l7.png" alt="">-->
                <!--                    <p>结项</p>-->
                <!--                </router-link>-->
            </div>
        </div>
        <div class="index-list flex-center between-box">
            <div class="index-list-box">
                <p class="index-title flex-center between-box">
                    系统通知
                    <router-link to="/home/systemMsg" class="more">
                        查看更多>
                    </router-link>
                </p>
                <div class="system-list">
                    <div
                            class="system-item ovHide"
                            v-for="item in noticeList"
                            :key="item.id"
                            :class="{ active: item.is_read === 0,red:item.notice_show === 2 }"
                            @click="toDetail(item,false)"
                    >
                       <div class="ovHide"> {{ item.title }}</div>
                        <el-button size="mini" type="primary" @click.stop="toFinancialBook(item)">进入台账</el-button>
                        <span>{{ item.add_time }}</span>
                    </div>
                </div>
            </div>
            <!--<div class="index-list-box">-->
            <!--<p class="index-title flex-center between-box">-->
            <!--动态-->
            <!--<router-link to="/home/dynamic" class="more"> 查看更多> </router-link>-->
            <!--</p>-->
            <!--<div class="dynamic-list">-->
            <!--<div-->
            <!--class="dynamic-item active"-->
            <!--v-for="item in dtList"-->
            <!--:key="item.id"-->
            <!--@click="toDetail(item,true)"-->
            <!--&gt;-->
            <!--<span>{{ item.add_time }}</span>-->
            <!--<p class="ovHide2">{{ item.title }}</p>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
        </div>
    </div>
</template>

<script>
    import {toDetail} from "../../tools";

    export default {
        name: "index",
        data() {
            return {
                isPayment:
                this.$store.state.user.department === "领导" ||
                this.$store.state.user.department === "财务部",
                noticeList: [],
                dtList: [],
                counts: {},
                timer: '',
                financiaBook:false
            };
        },
        created() {
            this.$emit("setIndex", [0, 0]);
            let that = this
            that.init()
            this.timer = setInterval(function () {
                //执行内容
                that.init()
            }, 5000);
            this.financiaBook = this.user.jid === 2 || this.user.jid === 3 || this.user.did === 4
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            toDetail(row, isDynamic) {
                this.toRead(row.id);
                let type = isDynamic ? -1 : (row.role === 1 ? 1 : row.role === 2 ? 0 : -1)
                toDetail(row,type)
            },
            toFinancialBook(row) {
                this.$router.push('/home/financialBook?oid='+row.pid)
            },
            toRead(id) {
                this.$api.apiContent
                    .toRead({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then(() => {
//          this.counts = res.data;
                    })
                    .catch(() => {
//          this.counts = {};
                    });
            },
            init() {
                this.$api.apiContent
                    .getCount({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.counts = res.data;
                    })
                    .catch(() => {
                        this.counts = {};
                    });
                this.$api.apiContent
                    .getNotice({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: 1,
                        limit: 6,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                page: 1,
                                limit: 6,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.list.map((item) => {
                            item.add_time = this.$tools.ZHDate(
                                new Date(item.add_time * 1000),
                                1
                            );
                        });
                        this.noticeList = res.data.list;
                    })
                    .catch(() => {
                        this.noticeList = [];
                    });
//                this.$api.apiContent
//                    .getDongtai({
//                        token: this.$store.state.user.token,
//                        timestamp: this.$tools.requestTime(),
//                        page: 1,
//                        limit: 4,
//                        sign: this.$tools.SHA256(
//                            {
//                                token: this.$store.state.user.token,
//                                timestamp: this.$tools.requestTime(),
//                                page: 1,
//                                limit: 4,
//                            },
//                            this.$store.state.user.key
//                        ),
//                    })
//                    .then((res) => {
//                        res.data.list.map((item) => {
//                            item.add_time = this.$tools.ZHDate(
//                                new Date(item.add_time * 1000),
//                                1
//                            );
//                        });
//                        this.dtList = res.data.list;
//                    })
//                    .catch(() => {
//                        this.dtList = [];
//                    });
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .index-column {
            margin-bottom: 20px;

            .column-item {
                width: 32%;
                height: 164px;
                background-color: #ffffff;
                border-radius: 6px;

                div {
                    margin: 0 auto;

                    img {
                        display: block;
                        width: 70px;
                        height: 70px;
                        margin-right: 23px;
                    }

                    .item {
                        margin: 0 auto;
                        font-size: 30px;
                    }
                }
            }
        }

        .index-executive {
            width: 100%;
            height: 200px;
            margin-bottom: 20px;
            padding: 20px 30px;
            background-color: #ffffff;
            border-radius: 6px;

            .index-title {
                font-size: 24px;
                line-height: 36px;
                color: #333333;
            }

            .index-executive-list {
                padding: 0 40px;
                margin-top: 25px;

                .index-executive-item {
                    display: block;
                    width: 85px;

                    img {
                        display: block;
                        width: 55px;
                        height: 55px;
                        margin: 0 auto 5px;
                        border-radius: 16px;
                    }

                    p {
                        text-align: center;
                        font-size: 20px;
                        line-height: 36px;
                        color: #333333;
                    }
                }
            }
        }

        .index-meet {
            width: 100%;
            height: 200px;
            margin-bottom: 20px;
            padding: 20px 30px;
            background-color: #ffffff;
            border-radius: 6px;

            .index-title {
                font-size: 24px;
                line-height: 36px;
                color: #333333;
            }

            .index-meet-list {
                padding: 0 40px;
                margin-top: 25px;

                .index-meet-item {
                    min-width: 85px;

                    img {
                        display: block;
                        width: 55px;
                        height: 55px;
                        margin: 0 auto 5px;
                        border-radius: 16px;
                    }

                    p {
                        text-align: center;
                        font-size: 20px;
                        line-height: 36px;
                        color: #333333;
                    }
                }

                .meet-jiantou {
                    margin-top: 20px;
                    width: 70px;
                }
            }
        }

        .index-list {
            .index-list-box {
                width: 100%;
                height: 449px;
                padding: 20px 30px;
                background-color: #ffffff;
                border-radius: 6px;

                .index-title {
                    font-size: 24px;
                    line-height: 36px;
                    color: #333333;
                    margin-bottom: 20px;

                    .more {
                        color: #999999;
                    }
                }

                .system-list {
                    .system-item {
                        display: flex;
                        align-items: center;
                        height: 61px;
                        line-height: 61px;
                        padding: 0 140px 0 25px;
                        border-bottom: 1px solid #f4f4f4;
                        position: relative;
                        font-size: 18px;
                        &:hover {
                            cursor: pointer;
                        }
                        &:last-child {
                            border-bottom: 0;
                        }

                        &.red {
                            color: red;
                        }
                        &::before {
                            position: absolute;
                            top: 25px;
                            left: 5px;
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                            background: #dddddd;
                        }

                        &.active {
                            &::before {
                                background: #3b77e7;
                            }
                        }

                        span {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 140px;
                            line-height: 60px;
                            text-align: right;
                            font-size: 16px;
                            color: #999999;
                        }
                    }
                }

                .dynamic-list {
                    overflow: hidden;

                    .dynamic-item {
                        display: block;
                        padding-left: 25px;
                        position: relative;
                        &:hover {
                            cursor: pointer;
                        }
                        /*&.active {*/
                        /*&::before {*/
                        /*background: #3b77e7;*/
                        /*}*/
                        /*}*/
                        /*&::before {*/
                        /*position: absolute;*/
                        /*top: 10px;*/
                        /*left: 5px;*/
                        /*content: "";*/
                        /*width: 10px;*/
                        /*height: 10px;*/
                        /*border-radius: 100%;*/
                        /*background: #dddddd;*/
                        /*z-index: 3;*/
                        /*}*/

                        /*&::after {*/
                        /*position: absolute;*/
                        /*top: 15px;*/
                        /*left: 10px;*/
                        /*content: "";*/
                        /*width: 1px;*/
                        /*height: 85px;*/
                        /*background: #f4f4f4;*/
                        /*}*/

                        span {
                            font-size: 16px;
                            line-height: 30px;
                            color: #999999;
                        }

                        p {
                            font-size: 18px;
                            line-height: 30px;
                            height: 60px;
                            color: #333333;

                            b {
                                color: #3b77e7;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
